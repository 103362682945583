import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step2 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Welcome | ${titleTrainingDays}`}
        description={
          "Welcome video by the coordinator of the Initial Qualification Programmes, Maria Jesús Marco Logroño."
        }
        canonical={"training-days/step-2/"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 2{" "}
        </p>
        <h1 className={"slideLeft "}>Welcome</h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <LazyIframe src={"JYGsaRFyihY"} title={"Welcome"} />
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-1/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-3/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>Welcome video by the coordinator of the Initial Qualification Programmes, Maria Jesús Marco Logroño.</p>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step2;
